@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery-description {
  color: gray !important;
  background: black !important;
  bottom: 0px !important;

}
.image-gallery-svg {
  width: 2% !important!;
  height: 25px !important;
}

.image-gallery-left-nav {
  transform: translate(0, 120%);
  position: absolute;
}

.image-gallery-right-nav {
  transform: translate(0, 120%);
  position: absolute;
}

.scrollinggallery {}

.scrollinggallery img {
    float: left;
    width:  100px;
    height: 100px;
    object-fit: cover;
}

.hovered {
  border: 2px black;
  display: block;
  transition: width 1s;
}

.hovered:hover {
  cursor:pointer;
}


.hoverdiv:hover {
  cursor:pointer;
  
}

@font-face {
  font-family: "Raleway";
  font-weight: bold;
  font-style: normal;
}

.gallerry-navigation {
  float: right;
}

mark {
  background-color:white;
  font-weight: bold;
  text-decoration: underline;
}

#cf {
  position:relative;
  height:140px;
  width:200px;
  margin:0 auto;
  float:left;
  display:block;
}

#cf img {
  position: relative;
  left:0;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  display:inline-block;
  height:100px;
  width:100px;
}

#cf img.top:hover {
  opacity:0;
}

body {
  font-family: "Raleway", sans-serif;
  font-size: 1.1em;
  margin: 0;
  text-decoration: none;

  background-image: url("linkhover.png");
  background-size: cover;
}

.background-color {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: #FEFDF9;
}

.background-color-white {
  min-height: 100vh;
  width: 100%;
  background-color: white;
  padding: 1em;
}

.active {
  border-bottom: 3px solid black;
}

.content {
  margin: auto;
  width: 100%;
  min-height: 100vh;
}

.vpad img {
  padding-top: 5em;
  padding-bottom: 5em;
}

.vertical {
     border-left: 3px solid black;
     display: inline-block;
     position: fixed;
     height: 200px;
     margin: auto;
     margin-left: 10em;
     margin-top: 8em;
 }



.centerwrap {
  width: 40%;
  display: table;
  margin: auto;
}

.centerwrap img {
  display: block;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
}

.embeddedgallery {
  width: auto;
  max-height: 30%;
  margin: auto;
  display: inline-block;
  float: left;
}

.embeddedgallery img {
  padding-right: 1em;
  margin-right: 1em;
}

.floatcenter {
  margin: 0 auto;
  display: block;
}

.floatcenter img {
  margin-top: 1.5em;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  height: auto;
}

.floatright {
  float: right;
  right: 4em;
  bottom: 2em;
  display: fixed;
}


.bcard img {
  height: 200px;
  width: 500px;
}

p {
  text-indent: 2em;
}
.copy {
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 2em;
  padding-right: 1em;
  width: 95%;
  max-width: 1000px;
  margin: auto;
  display: block;
  overflow: hidden;
  background-color: white;
}

.copy img {
  vertical-align:top;
  display: block;
  margin-bottom: 1em;
  padding-right: 1em;
  float: left;
}

.copy a {
  text-decoration: none;
  color: #4F7388;
}

.labelledimage {
  float: left;
}

.labeltext {
  background-color:gray;
  text-align: center;
  padding-right: 20px;
}

.copy a:hover {
  background-image: url("linkhover.png");
  color: white;
}

.row {
  width: 60%;
  height:200px;
  margin: 0 auto;
}

.landingtile {
  float: left;
  width: 44%;
  margin: 0 auto;
  padding-right:3%;
  padding-left:3%;
}

.mutetext {
  color: grey;
}


.landingtile img {
  width: 100%;
}

.imagehover {
  color: white;
}

.imagehover a {
  text-decoration: none;
}


.absolutecg {
  display: flex; 
  width: 30%;
  height: 20%;
  object-fit: cover;
}

.texthover {
  display: block;
  max-width: 100%;
  position: fixed;
  padding-top: 2em;
  padding-left: 2em;
  text-decoration: none;
}

.colorgram {
  width: 30%;
  height: 20%;
  float: left;
  object-fit: cover;
  padding-right: 2em;
}

.portrait {
  width: 450px;
  height: 450px;
  object-fit: scale-down;
  padding-right: 2em;
}


.worklist li {
  list-style-type: none;
  border: 2px solid black;
}


.copy p {
  float: left;
}

.thumbnail {
  display: block;
  object-fit: cover;
  height: 200px;
  width: 200px;
  margin: auto;
  float: left;
}

.thumbnailtext {
  float:right;
  display: inline-block;
}

.image-gallery-thumbnails-wrapper {
  height: 50px;
  display: block;
}

.image-gallery-thumbnails {
  height: 50px;
  display: inline-block;
  margin: auto;
}


.image-gallery-thumbnails img {
  display: block;
  margin: auto;
  float: bottom;
}

.widevideo {
    width: 100%;
    max-height: 260px;
}

#video {
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    -moz-transform:rotateY(180deg); /* Firefox */
}

.floattop {
  position: absolute;
  height: 0px;
  font-size: 30px;
}

.floattop a {
  float: left;
  text-decoration: none;
  position: absolute;
  padding-left:1em;
  padding-right:1em;
  color: black;
  background-color: white;
}

.gallery {
  margin: 0 auto;
  width: 90%;
  height: 100%;
  background-color: white;
}

.imagefill {
  margin: 0 auto;
  display: block;
}

.imagefill img {
  margin-top: 1.5em;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  height: auto;
}
.footer {
  width:100%;
  margin: 0 auto;
  bottom:0; /* stick to bottom */
}

.footer a {
  float: left;
  text-decoration: none;
  background-color:gray;
  padding: 1em;
}

.portal {
  background-color: white;
  height: 60px;
  width: 100%;
  display: block;
  margin-bottom: 1em;
}

.portal a {
  float: left;
  text-decoration: none;
  position: absolute;
}

.portal img {
  height: 60px;
  width: auto;
}

.portal ul {
  list-style-type: none;
}

.hovertext {
  text-align: left;
  color: transparent;
}

.hovertext:hover {
  background-color: rgba(255, 255, 255, 0.65);
  color: black;
}

.nav {
  margin: auto;
  width: inherit;
  overflow: hidden;
  display: block;
  top: 1.5em;
  position: absolute;
}

.nav a {
  text-decoration: none;
  color: black;
  float: right;
  padding: 0.05em;
  display: inline-block;
  position: relative;
  margin: auto;
  margin-top: 9px;
  margin-left: 0.5em;
  margin-right: 0.5em;
}


.nav a:hover {
  text-decoration: none;
  color: #fffefa;
  background-image: url("linkhover.png");
  background-size:cover;
}

.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 40px;
}

.nav img {
  height: 40px;
  width: auto;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}



#changingviews {
  background-image: url("artofscience-header.jpg");
  background-size: cover;
  border: 2px solid white;
}

#treeoflifeanddeath {
  background-image: url("cstree-header.png");
  background-size: cover;
  border: 2px solid white;
}

#threelangips {
  background-image: url("threelangips-header.png");
  background-size: cover;
  border: 2px solid white;
}

#fivetastes {
  background-image: url("fivetastes-header.png");
  background-size: cover;
  border: 2px solid white;
}

#milleniaasmoment {
  background-image: url("mam-header.png");
  background-size:cover;
  border: 2px solid white;
}

#flowers {
  background-image: url("flowers-header.png");
  background-size: cover;
  border: 2px solid white;
}


#portfolio {
  background-image: url("portfolio-header.png");
  background-size: cover;
  color: black;
  border: 2px solid white;
}

#dickinsonthenshakespeare {
  background-image: url("dickinson-header.png");
  background-size: cover;
  color: black;
  border: 2px solid white;
}


#digitalsand {
  background-image: url("digitalsand-header.png");
  background-size: cover;
  border: 2px solid white;
}

.info-modal {
  position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  border: 1px solid #dadada;
  border-radius: 5px;
  box-shadow: inset 0 0 0.5em 0.5em;
  padding: 5%;
  background-clip: border-box;
}

.papermodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:80%;
  max-height: 90%;
  overflow:scroll;
  background: white;
  padding: 1em;
}

.contentmodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:70%;
  background: white;
  padding: 1em;
  margin-left: 2em;
}


button:focus {
    outline: 0 !important;
}

.info-button {
  display: block; 
  position: fixed;
  float: right;
  top:10px;
  right:10px;
  outline:none;
  font-size: 20px;
}

button {
  background-color: white;
}

.darkbutton {
  background-image: url("linkhover.png");
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 25px;
}

.whitetext {
  color: white;
}


.hamburger {
  display: none;
  right: 2em;
  top: 0em;
}

.vfill {
  height: 100%;
  width: 100%;
  position: fixed;
}

@media screen and (max-width: 1100px) {
  .nav a {display: none;}
  .nav .hamburger {
    float: right;
    display: block;
    position: absolute;
  }
  .copy {
    max-width: 93%;
    padding-left: 0.5em;
    padding-right: 0.5em;
    margin: 0 auto;
  }
  .imagefill img {
    max-width: 100%;
  }
  .floatcenter img {
    max-width: 100%;
    max-width: 80%;

  }
  .landingtile {
    margin: auto;
    max-width: 45%;
  }
  .thumbnail {
    height: auto;
    margin: 0 auto;
  }
}
@media screen and (max-width: 600px) {
  .thumbnail {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1100px) {
  .nav.responsive {
    position: relative;
  }
  .nav.responsive {
    position: absolute;
    background-color: white;
    height: 50%;
    width: inherit;
    padding-top:5em;
  }
  .nav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .portrait {
    width: 100%;
    height: auto;
  }
}

