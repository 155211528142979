ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: white;
  padding: 0;
}
ul.header li a {
  color: black;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}

ul.header li a img {
  width:20px;
  display: inline-block;
}

ul.header li a:hover {
  background-color: gray;
}
